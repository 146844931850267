@import url('./global.scss');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--background); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html,
#root,
.App {
  height: 100%;
  /* background-color: black; */
}

.App {
  height: 100vh;
}
a-scene {
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 1;
}
#webcam {
  /* z-index: 20; */
  /* opacity: 0.4; */
  position: absolute;
  background-size: 100% 100%;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}
